import { render, staticRenderFns } from "./RegisterInstitutional.vue?vue&type=template&id=6cd378d9&scoped=true"
import script from "./RegisterInstitutional.vue?vue&type=script&lang=js"
export * from "./RegisterInstitutional.vue?vue&type=script&lang=js"
import style0 from "./RegisterInstitutional.vue?vue&type=style&index=0&id=6cd378d9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cd378d9",
  null
  
)

export default component.exports